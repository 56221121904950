.twoColumns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.fontMediumBold {
  font-size: 0.85rem;
  font-weight: 700;
}

.priceListRow {
  display: grid;
  grid-template-rows: (min-height, auto);
  gap: 1rem;
}
.dashColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.dashColumnTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dashColumnThree {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.columns {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
}

.columnThree {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.dashColumnFour {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.dashIntro {
  text-align: center;
  font-size: 1rem;
}

.cardIcon {
  text-align: left;
}

.smallFont {
  font-size: 0.75rem;
}
.icons {
  margin-bottom: 1.25rem;
}

.icons img {
  width: 150px;
  height: 150px;
  text-align: center;
  box-shadow: 2px 2px 10px 1px #616161;
  border-radius: 100%;
}

.activePkg {
  background-color: rgb(234, 255, 0);
  color: #616161;
  border-radius: 8px;
  padding: 0.85rem;
  margin-top: 5px;
  font-weight: 500;
}

.activePkg:hover {
  background-color: rgb(162, 170, 0);
  color: #ffffff;
}

.activationButton {
  background-color: rgb(12, 249, 75);
  color: #616161;
  border-radius: 8px;
  padding: 0.85rem;
  margin-top: 5px;
  font-weight: 500;
  border: 0;
  height: 60px;
}

.activationButton:hover {
  background-color: rgb(0, 91, 27);
  color: #ffffff;
}

.nonActiveButton {
  background-color: rgb(255, 141, 141);
  color: #616161;
  border-radius: 8px;
  padding: 0.85rem;
  margin-top: 5px;
  font-weight: 500;
  border: 0;
}

.nonActiveButton:hover {
  background-color: rgb(174, 0, 0);
  color: #ffffff;
}
.refLink {
  text-align: center;
  font-size: 1.25rem;
}

.refLink a {
  text-decoration: none;
}

/* Custom Styles for Sliding Dropdown */
.submenu {
  list-style-type: none;
  padding-left: 20px;
}

.submenu-item {
  display: block;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.submenu-item:hover {
  color: #007bff;
}

.menu-link {
  cursor: pointer;
}

.submenu.open {
  max-height: 200px; /* Adjust as necessary */
}

.submenu-item {
  padding: 10px 15px;
  font-size: 14px;
  color: #444;
}

.submenu-item:hover {
  color: #007bff;
  background-color: #f8f9fa;
}

@media only screen and (max-width: 1024px) {
  .dashColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .dashColumnThree {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .dashColumnFour {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .refLink {
    text-align: center;
    font-size: 1rem;
  }

  .columnThree {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

@media only screen and (max-width: 768px) {
  .dashColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .dashColumnThree {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .dashColumnFour {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .dashIntro {
    font-size: 0.75rem;
  }

  .dashIntro img {
    width: 100;
  }

  .cardIcon {
    text-align: center;
  }

  .refLink {
    font-size: 0.85rem;
  }

  .columns {
    font-size: 0.65rem;
    font-weight: bold;
    gap: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 345px) {
  .refLink {
    font-size: 0.75rem;
  }
}
