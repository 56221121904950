.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.features-list ul li {
  font-size: 0.75rem;
}

.single-pricing-package {
  min-height: 520px;
}

.single-user-ditails-card {
  padding: 1rem;
}

.user-banner-wrap {
  padding-top: 95px;
  padding-bottom: 25px;
  background-image: url(./assets/img/banner/bg-2.png);
  background-size: cover;
}

.admin-banner-wrap {
  padding-top: 95px;
  padding-bottom: 25px;
  background-image: url(./assets/img/banner/admin-bg.png);
  background-size: cover;
}

.no-data {
  background-color: #0c0f00;
}

.mobile-nav .sidebar-nav .metismenu ul a::before {
  border: 0px solid #fff;
}

.no-pkg {
  max-width: 215px;
}

.section-padding-medium {
  padding: 30px 0px;
}

/* Example CSS */
.mobile-nav {
  display: none; /* Hide by default */
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: #0f0f0f;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.mobile-nav.open {
  display: block;
  transform: translateX(0);
}

.mobile-nav .sidebar-nav .metismenu {
  align-items: flex-start;
}

.overlay {
  display: none; /* Hide by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.overlay.open {
  display: block;
}

.sub-menu ul li {
  list-style: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Make the table scrollable horizontally on small screens */
.table-responsive {
  overflow-x: auto;
}

/* Base font size for table */
.table {
  font-size: 1rem; /* Default size */
}

.form-control {
  border: 1px solid #696969b8;
}

.alert-dismissible .btn-close {
  font-size: 0.65rem;
}

/* Responsive text sizes */
@media (max-width: 1200px) {
  .table {
    font-size: 0.95rem; /* Slightly smaller text on medium screens */
  }
}

@media (max-width: 992px) {
  .table {
    font-size: 0.85rem; /* Smaller text on tablets */
  }
}

@media (max-width: 768px) {
  .table {
    font-size: 0.8rem; /* Even smaller text on mobile screens */
  }

  .section-padding {
    padding: 80px 0px;
  }
}

@media (max-width: 576px) {
  .table {
    font-size: 0.75rem; /* Smallest text on very small screens */
  }
}
